<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="日期" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
              <a-range-picker @change="onChangeDate"  format="YYYY-MM-DD HH:mm:ss" showTime :defaultValue="[form.start_time,form.end_time]" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.domain" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">
            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
          id="table"
          class="aaaaa"
          :size="tableSize"
          :scroll="{ x: 120,y:400 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :row-key="record => record.site_id"
          :data-source="data"
          :pagination=false
          :loading="loading"
          bordered
          @change="handleTableChange"
      >
        <template slot="操作" slot-scope="data">
              <span>
                <a-button size="small" type="primary" @click="handleView(data.domain)">查看明细</a-button>
              </span>

        </template>

      </a-table>
    </div>



  </div>
</template>
<script>
import  {sourceVisitor} from "@/axios/source.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from "moment";
const columns = [//列描述
  {
    align:'center',
    title: '域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '备注',
    dataIndex: 'remark',
  },
  {
    align:'center',
    title: '访问量',
    dataIndex: 'visit_num',
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },
];
export default {
  filters: {

  },
  mixins: [myMixin],
  data() {

    return {
      languagesList:[],
      form: {
        page:1,
        page_size: 10,
        site_id:'',
      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        domain:'',
      },
      dataArr:[],
      tableBox:'',
      tableShow:true,
    }

  },
  created() {
    this.form.site_id = this.$route.query.site_id;
    this.form.es_id = this.$route.query.es_id;
    this.form.start_time =moment().format('YYYY-MM-DD 00:00:00');
    this.form.end_time =moment().format('YYYY-MM-DD 23:59:59');
  },


  mounted() {
    let that=this
    this.fetch();
    this.tableBox = document.querySelector('#table');
    this.tableBox.addEventListener('mousewheel', this.listenBottomOut);
  },

  methods:{
    moment,
    onChangeDate(date, dateString) {//时间

      this.form.start_time=dateString[0];
      this.form.end_time=dateString[1];

    },

    async fetch (params = {}) {


      this.loading = true;
      let data=await sourceVisitor({...params,...this.form})

      this.loading = false;
      const pagination = { ...this.pagination };
      pagination.total = data.data.total;
      if(data.data.data.length>0){
        this.data.push(...data.data.data)
      }else{
        this.tableShow = false;
      }
      this.pagination = pagination;
    },


    handleView(domain){
      this.$router.push({path:'/sourceWed',query:{
        es_id:this.form.es_id,
        site_id:this.form.site_id,
        referrer:domain,
        end_time:this.form.end_time,
        start_time:this.form.start_time,
        }})
    },
    listenBottomOut(){
      let d = document.querySelector('#table .ant-table-body');
      let dHeight =d.clientHeight+d.scrollTop+10;
      if(dHeight>=d.scrollHeight){

        if(this.tableShow){
          this.form.page = this.form.page+1;
          this.fetch();
        }

      }
    },



  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}

</style>


